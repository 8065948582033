define("backend/pods/client/billings/controller", ["exports", "backend/prototypes/tables/headers/billings-waiting", "backend/prototypes/tables/headers/billings-unpaid", "backend/prototypes/tables/headers/billings-paid", "backend/prototypes/tables/headers/billings-cancelled", "backend/prototypes/tables/headers/billings-template", "backend/config/environment"], function (_exports, _billingsWaiting, _billingsUnpaid, _billingsPaid, _billingsCancelled, _billingsTemplate, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // Optional if you want date formatting
  function removeClientColumn(tableColumns) {
    return tableColumns.filter(function (col) {
      var attr = col.get('attr');
      return attr !== 'client.displayName' && attr !== 'lead.displayName';
    });
  }

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: ['page', 'size', 'create'],
    page: 1,
    size: 100,
    tableColumnsTemplate: removeClientColumn(_billingsTemplate.default),
    tableColumnsWaiting: removeClientColumn(_billingsWaiting.default),
    tableColumnsPaid: removeClientColumn(_billingsPaid.default),
    tableColumnsUnpaid: removeClientColumn(_billingsUnpaid.default),
    tableColumnsCancelled: removeClientColumn(_billingsCancelled.default),
    clientSort: {
      sortBy: 'number',
      order: 'desc'
    },
    actions: {
      createCancellationInvoice: function createCancellationInvoice() {
        var _this = this;

        // Prompt user for the invoice number
        var invoiceNumber = prompt('Bitte Rechnungs-ID eingeben:');

        if (!invoiceNumber) {
          alert('Bitte gib eine Rechnungs-ID ein.');
          return;
        }

        var url = "".concat(_environment.default.host, "/billings/").concat(invoiceNumber, "/cancellation");
        var today = new Date().toISOString().split('T')[0]; // 'YYYY-MM-DD'

        console.log('Calling cancellation API for invoice:', invoiceNumber);
        return new Promise(function (resolve, reject) {
          _this.get('session').authorize('authorizer:hm-token', function (headerName, headerValue) {
            var _headers;

            var headers = (_headers = {}, _defineProperty(_headers, headerName, headerValue), _defineProperty(_headers, 'Content-Type', 'application/json'), _headers);

            _this.get('ajax').request(url, {
              method: 'PUT',
              dataType: 'json',
              headers: headers,
              data: JSON.stringify({
                data: {
                  date_of_cancellation: today
                }
              })
            }).then(function () {
              alert("Stornorechnung f\xFCr ".concat(invoiceNumber, " wurde erstellt."));

              _this.send('refreshModelAction');

              resolve();
            }).catch(function (error) {
              var _error$payload;

              console.error('Error creating cancellation invoice:', error);

              _this.set('errorMessage', (error === null || error === void 0 ? void 0 : (_error$payload = error.payload) === null || _error$payload === void 0 ? void 0 : _error$payload.error) || 'Stornierung fehlgeschlagen');

              reject(error);
            });
          });
        });
      }
    }
  });

  _exports.default = _default;
});